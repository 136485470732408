var sideBarFields = [
  {
    id: 5,
    fieldName: "",
    state: "",
    moistureLevel: 0,
    decision: "",
    decisionDesc: "",
    long: 0,
    lat: 0,
    area: "",
    crop: "",
    title: "",
    hasButton: true,
    RCLevels: [1],
    dataSet: [1],
    labels: [1],
    chartName: ""
  },
  {
    id: 6,
    fieldName: "",
    state: "",
    moistureLevel: 0,
    decision: "",
    decisionDesc: "",
    long: 0,
    lat: 0,
    area: "",
    crop: "",
    title: "",
    hasButton: true,
    RCLevels: [2],
    dataSet: [2],
    labels: [2],
    chartName: ""
  },
  {
    id: 7,
    fieldName: "",
    state: "",
    moistureLevel: 0,
    decision: "",
    decisionDesc: "",
    long: 0,
    lat: 0,
    area: "",
    crop: "",
    title: "",
    hasButton: true,
    RCLevels: [3],
    dataSet: [3],
    labels: [3],
    chartName: ""
  }
];

export var sideBarFields;
